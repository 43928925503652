import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import MultiStepForm from "../components/multi-step-form";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// Import additional components as needed

// Note: The `$id` variable is used to fetch a specific form by its ID
export const query = graphql`
	query FormById($id: String!) {
		wpForm(id: { eq: $id }) {
			formFields {
				category {
					... on WpInsuranceCategory {
						title
					}
				}
				leadValue
				step1ContactPreferences
				step2PersonalDetails
				step3BusinessDetails
				hasStep4
				hasStep5
				hasStep6
				hasStep7
				step4Heading
				step5Heading
				step6Heading
				step7Heading
				step4 {
					title
					options
					inputType
					required
					minNumber
				}
				step5 {
					title
					options
					inputType
					required
					minNumber
				}
				step6 {
					title
					options
					inputType
					required
					minNumber
				}
				step7 {
					title
					options
					inputType
					required
					minNumber
				}
			}
			title
		}
	}
`;

const FormTemplate = ({ data }) => {
	const form = data.wpForm;
	const {
		step1ContactPreferences,
		step2PersonalDetails,
		step3BusinessDetails,
		hasStep4,
		hasStep5,
		hasStep6,
		hasStep7,
		step4,
		step5,
		step6,
		step7,
	} = form.formFields;

	return (
		<Layout>
			<GatsbySeo title={form.title} language="en" noindex nofollow />
			<section className="bg-med-grey py-5 py-lg-7">
				<MultiStepForm formWp={form.formFields} title={form.title} />
			</section>
		</Layout>
	);
};

export default FormTemplate;
